/* Provide sufficient contrast against white background */

/* these below seems not in bootstrap .scss loaded files? */
.float-right {
    float:right;
}
.btn-block {
    display: block;
    width: 100%
}
.font-weight-bold {
    font-weight: 700!important
}
.btn-block + .btn-block {
    margin-top: 0.5rem;
}
/* buttons.scss */

html, body {
    font-family: canada-type-gibson, sans-serif;
    font-weight: 300;
    font-style: normal;
}

h1, h2, h3 {
    font-weight: 600;
    color: #8a4e8c
}

a, .btn-link {
    color: #fbc132; /*#0366d6*/    
}

.btn-primary {
    color: #fff;
    background-color: #fbc132;    
    border: none;
    transition: background-color 0.5s ease;
    padding: 8px 15px;
    font-size: 18px;
    text-transform: uppercase;
}
    .btn-primary:hover {
        background-color: #8a4e8c 
    }
.content {
    padding-top: 1.1rem;
}

.activities-table td {
    vertical-align: middle;
    padding: 0.4rem;
}

.btn-outline-primary {
    border: 1px solid #8a4e8c;
    color: #8a4e8c;
    transition: background-color 0.5s ease;
    text-transform: uppercase;
    background-color: transparent;
}

    .btn-outline-primary:hover {
        background-color: #8a4e8c; 
        color: #FFF;
    }



.page {
    position: relative;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1;
}

.sidebar {
    /*background-color: #003876;*/
    background: linear-gradient(#8a4e8c, #422255);
}

.top-row {
    background-color: #f7f7f7;
    border-bottom: 1px solid #d6d5d5;
    justify-content: flex-end;
    height: 3.5rem;
    display: flex;
    align-items: center;
}

    .top-row ::deep a, .top-row .btn-link {
        white-space: nowrap;
        margin-left: 1.5rem;
    }

    .top-row a:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
    }

@media (max-width: 640.98px) {
    .top-row:not(.auth) {
        display: none;
    }

    .top-row.auth {
        justify-content: space-between;
    }

    .top-row a, .top-row .btn-link {
        margin-left: 0;
    }
}

@media (min-width: 641px) {
    .page {
        flex-direction: row;
    }

    .sidebar {
        width: 250px;
        height: 100vh;
        position: sticky;
        top: 0;
    }

    .top-row {
        position: sticky;
        top: 0;
        z-index: 1;
    }

   
}