
.navbar-toggler {
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-top-row {
    height: 3.5rem;
    background-color: rgba(0,0,0,0.4);
}

.pl-4, .px-4 {
    padding-left: 1.5rem !important;
}

.navbar-brand {
    font-size: 1.1rem;
}
    
.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.oi {
    width: 2rem;
    font-size: 1.1rem;
    vertical-align: text-top;
    top: -2px;
}

.nav-item {
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
}

    .nav-item:first-of-type {
        padding-top: 1rem;
    }

    .nav-item:last-of-type {
        padding-bottom: 1rem;
    }

    .nav-item a {
        color: #d7d7d7;
        border-radius: 4px;
        height: 3rem;
        display: flex;
        align-items: center;
        line-height: 3rem;        
    }

        .nav-item  a.active {
            background-color: rgba(255,255,255,0.25);
            color: white;
        }

        .nav-item  a:hover {
            background-color: rgba(255,255,255,0.1);
            color: white;
        }

@media (min-width: 641px) {
    .navbar-toggler {
        display: none;
    }

    .collapse {
        /* Never collapse the sidebar for wide screens */
        display: block !important;
    }
}

